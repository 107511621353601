
import {
  Component,
  Vue
} from 'vue-property-decorator'
import YardService from './services/yard-service'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  required
} from 'vuelidate/lib/validators'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
import DateHelper from '@/mixins/date-mixins'
import {
  NotifyModule
} from '@/store/modules/NotifyModule'
  @Component({
    name: 'Note',
    components: {}
  })
export default class Delivery extends Vue {
    protected isLoader = false
    protected bowsers: any = []
    protected bowser: any = {}

    $v: Vuelidate

    @Validations()
    validations = {
      bowser: {
        arrival_date: {
          required
        }
      }
    }

    created () {
      this.boot()
    }

    protected boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.populateDispatchedBowsers()
    }

    protected populateDispatchedBowsers () {
      YardService.getDispatchedBowsers(AuthModule.party.id).then(response => {
        response.data.forEach(bowser => {
          this.bowsers.push({
            value: {
              dispatch_note_id: bowser.id,
              number: bowser.vehicle_number,
              wheels: bowser.vehicle_wheels,
              capacity: bowser.vehicle_capacity,
              driver: bowser.driver,
              item_id: bowser.item_id,
              content_type: bowser.content_type,
              location_id: bowser.location_id,
              seal_up: bowser.seal_up,
              seal_down: bowser.seal_down,
              dispatch_date: bowser.dispatch_date,
              total_tonnage: bowser.total_tonnage * 1000,
              arrival_date: bowser.arrival_date === null ? DateHelper.today('dd/mm/yyyy') : bowser
                .arrival_date,
              is_seal_mismatch: bowser.is_seal_mismatch === 1,
              is_quantity_mismatch: bowser.is_quantity_mismatch === 1,
              remark: bowser.remark === null ? null : bowser.remark
            },
            text: bowser.vehicle_number
          })
        })
      })
    }

    protected save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyModule.set('Arrival date is required, Please check')
        return false
      }

      this.isLoader = true
      const data: any = {
        dispatch_note_id: this.bowser.dispatch_note_id,
        arrival_date: this.bowser.arrival_date,
        location_id: AuthModule.party.id,
        item_id: this.bowser.item_id,
        total_tonnage: this.bowser.total_tonnage,
        is_seal_mismatch: this.bowser.is_seal_mismatch,
        is_quantity_mismatch: this.bowser.is_quantity_mismatch,
        remark: this.bowser.remark,
        approval_remark: null,
        modified_user: AuthModule.user.fullname
      }

      YardService.save(data).then((response) => {
        this.isLoader = false
        this.reset()
        this.populateDispatchedBowsers()
        ToastModule.message(response.data.message)
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected reset () {
      this.$v.bowser.$reset()
      this.bowsers = []
      this.$nextTick(() => {
        this.bowser = {}
      })
    }
}

