import APIService from '@/services/api-service'
const resource = '/yard'

class YardService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getYards () {
    return this.connector.get(`${resource}/all-yards`)
  }

  public getDispatchedBowsers (yardId) {
    return this.connector.get(`${resource}/${yardId}/dispatched-bowsers`)
  }

  public save (data: any) {
    return this.connector.post(`${resource}/inventory/save`, { data: data })
  }
}

export default new YardService()
